import React from "react";
import Lottie from "react-lottie";
import Layout from "../../../components/Layout";
import Seo from "../../../components/seo";
import ogIMage from "../../../images/og.png";
import { Link, graphql, useStaticQuery } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import * as Emerging from "../../../animations/Emerging.json";
import * as Growing from "../../../animations/Growing.json";
import * as Innovation from "../../../animations/Innovation.json";
import * as Integration from "../../../animations/integration.json";
import ProjectGrid from "../../../components/ProjectGrid";
import BlogCtaBanner from "../../../components/BlogCtaBanner";
import bgImage from "../../../images/aichapter/bg-img-AI.jpg";

const LeverageGenAIPage = () => {
    const aicasestudies = useStaticQuery(graphql`
        {
            allContentfulCasestudies(
                sort: { order: DESC, fields: createdAt }
                filter: { type: { eq: "AI" } }
            ) {
                nodes {
                    id
                    name
                    logo {
                        gatsbyImageData(placeholder: BLURRED, quality: 100)
                        title
                    }
                    services {
                        id
                        title
                    }
                    description {
                        description
                    }
                    doneBy {
                        name
                    }
                    backgroundType
                    backgroundMedia {
                        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
                        title
                        file {
                            contentType
                            url
                        }
                    }
                    colorTheme
                    thumbnailTheme
                    slug
                    type
                }
            }
        }
    `);
    const NavbarTheme = {
        color: "light",
        paddingTop: "0px",
    };
    return (
        <Layout NavbarTheme={NavbarTheme}>
            <Seo
                title="Leverage GenAI l GenAI Services - Sedin"
                description="Unleash GenAI's transformative potential with Sedin. We merge human ingenuity & tech to make informed decisions, & scale operations for business growth."
                image={`https://sedintechnologies.com${ogIMage}`}
            />
            <section
                className="AI-common-herostyle leverage-AI"
                style={{
                    background: `url(${bgImage})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center center",
                }}
            >
                <div className="container container--banner">
                    <div className="AI-common-herostyle__text">
                        <div className="AI-common-herostyle__text-breadcrumbs text-fw-normal gap-2x text-clr-primary text-clr-white">
                            <Link className="text-decor-none" to="">
                                AI Chapter
                            </Link>
                            /
                            <Link className="text-decor-none" to="">
                                Leverage GenAI
                            </Link>
                        </div>
                        <h1 className="text-h1 AI-common-herostyle__text-header text-fw-medium gap-2x text-clr-white">
                            Reinvent business with Gen AI
                        </h1>
                        <p className="text-p1 AI-common-herostyle__text-description text-fw-regular gap-2x text-clr-white">
                            Empower your business with the transformative potential of generative
                            AI, driving innovation and efficiency in your product landscape
                        </p>
                        <div>
                            <Link className="sdn-cta-btn" to="/contact/">
                                Contact us
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
            <section className="leverage-genAI-clients">
                <div className="container container--first">
                    <div className="leverage-genAI-clients__text">
                        <div className="text-h1 text-fw-medium gap-3x">
                            Trusted by global businesses
                        </div>
                        <p className="text-p2 text-fw-regular text-clr-secondary gap-4x">
                            Empowering business growth through our AI expertise, delivered daily by
                            the proficient professionals within the Sedin family, catering to
                            category leaders and enterprises alike.
                        </p>
                    </div>
                    <div className="leverage-genAI-clients__grid">
                        <div className="leverage-genAI-clients__grid-logo">
                            <StaticImage
                                src="../../../images/aichapter/clients/gaea.png"
                                placeholder="blurred"
                                alt="client-logo"
                                loading="lazy"
                            />
                        </div>
                        <div className="leverage-genAI-clients__grid-logo">
                            <StaticImage
                                src="../../../images/aichapter/clients/ags_health_logo.jpg"
                                placeholder="blurred"
                                alt="client-logo"
                                loading="lazy"
                            />
                        </div>
                    </div>
                </div>
            </section>
            <section className="leverage-genAI-content">
                <div className="container container--mid-v2">
                    <div className="leverage-genAI-content__text">
                        <div className="text-h1 text-fw-medium gap-3x">
                            Bringing Generative AI to real-world experiences for business
                        </div>
                        <p className="text-p2 text-fw-regular text-clr-secondary gap-1x">
                            We are in the age of generational change in artificial intelligence
                            applications and services, with potential to reshape industries and
                            unlock great possibilities for creativity, innovation, and
                            problem-solving.
                        </p>
                        <p className="text-p2 text-fw-regular text-clr-secondary gap-2x">
                            At Sedin, we combine human ingenuity with AI-powered technology in
                            shaping the future of products. From GenAI to automation, we stay ahead
                            of technology trends, ensuring our clients make informed business
                            decisions at precisely the right moment. Leveraging our structured
                            approach, we tap into the potential of generative AI to build
                            industry-specific solutions that empower enterprises to scale their
                            operations and fuel business growth.
                        </p>
                    </div>
                </div>
            </section>
            <section className="leverage-genAI-services">
                <div className="container container--mid-v2">
                    <div className="leverage-genAI-services__text">
                        <div className="text-h1 text-fw-medium gap-4x">
                            Accelerating growth of the business with our AI expertise
                        </div>
                    </div>
                    <div className="solution-grid gap-3x">
                        <div className="solution-item">
                            <div className="animation">
                                <Lottie
                                    options={{
                                        loop: true,
                                        autoplay: true,
                                        animationData: Emerging,
                                        rendererSettings: {
                                            preserveAspectRatio: "xMidYMid slice",
                                        },
                                    }}
                                />
                            </div>
                            <div className="content">
                                <h3 className="text-h3 text-fw-medium text-clr-primary gap-1x">
                                    Strategic Guidance
                                </h3>
                                <p className="text-p3 text-fw-regular text-clr-secondary">
                                    Gain a clear understanding of near-term generative AI use cases,
                                    risks, and limitations relevant to your industry and business
                                    function.
                                </p>
                            </div>
                        </div>
                        <div className="solution-item">
                            <div className="animation">
                                <Lottie
                                    options={{
                                        loop: true,
                                        autoplay: true,
                                        animationData: Growing,
                                        rendererSettings: {
                                            preserveAspectRatio: "xMidYMid slice",
                                        },
                                    }}
                                />
                            </div>
                            <div className="content">
                                <h3 className="text-h3 text-fw-medium text-clr-primary gap-1x">
                                    Exploratory Workshops
                                </h3>
                                <p className="text-p3 text-fw-regular text-clr-secondary">
                                    Explore the potential of generative AI based on your unique
                                    business and customer needs, alongside an assessment of your
                                    existing technology infrastructure.
                                </p>
                            </div>
                        </div>
                        <div className="solution-item">
                            <div className="animation">
                                <Lottie
                                    options={{
                                        loop: true,
                                        autoplay: true,
                                        animationData: Innovation,
                                        rendererSettings: {
                                            preserveAspectRatio: "xMidYMid slice",
                                        },
                                    }}
                                />
                            </div>
                            <div className="content">
                                <h3 className="text-h3 text-fw-medium text-clr-primary gap-1x ">
                                    Actionable Strategy
                                </h3>
                                <p className="text-p3 text-fw-regular text-clr-secondary">
                                    Develop a comprehensive generative AI strategy with a
                                    prioritized roadmap, ensuring alignment with your organization's
                                    objectives and delivering tangible business value.
                                </p>
                            </div>
                        </div>
                        <div className="solution-item">
                            <div className="animation">
                                <Lottie
                                    options={{
                                        loop: true,
                                        autoplay: true,
                                        animationData: Integration,
                                        rendererSettings: {
                                            preserveAspectRatio: "xMidYMid slice",
                                        },
                                    }}
                                />
                            </div>
                            <div className="content">
                                <h3 className="text-h3 text-fw-medium text-clr-primary gap-1x ">
                                    Responsible Deployment
                                </h3>
                                <p className="text-p3 text-fw-regular text-clr-secondary">
                                    Build a preliminary deployment plan for responsible AI
                                    implementation, including prioritized experiments and validation
                                    protocols to address ethical and practical considerations.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="leverage-genAI-statistics">
                <div className="container container--py">
                    <div className="leverage-genAI-statistics__grid">
                        <div className="leverage-genAI-statistics__grid-text">
                            <h2 className="text-h2 text-fw-medium text-clr-primary gap-2x">
                                Statistics
                            </h2>
                        </div>
                        <div className="leverage-genAI-statistics__grid-content">
                            <div className="leverage-genAI-statistics__grid-content-item">
                                <div className="leverage-genAI-statistics__grid-content-item__value">
                                    2+
                                </div>
                                <div className="leverage-genAI-statistics__grid-content-item__name">
                                    Years of experience
                                </div>
                            </div>
                            <div className="leverage-genAI-statistics__grid-content-item">
                                <div className="leverage-genAI-statistics__grid-content-item__value">
                                    13+
                                </div>
                                <div className="leverage-genAI-statistics__grid-content-item__name">
                                    GenAI Solutions Delivered
                                </div>
                            </div>
                            <div className="leverage-genAI-statistics__grid-content-item">
                                <div className="leverage-genAI-statistics__grid-content-item__value">
                                    15+
                                </div>
                                <div className="leverage-genAI-statistics__grid-content-item__name">
                                    AI Consultants
                                </div>
                            </div>
                            <div className="leverage-genAI-statistics__grid-content-item">
                                <div className="leverage-genAI-statistics__grid-content-item__value">
                                    7+
                                </div>
                                <div className="leverage-genAI-statistics__grid-content-item__name">
                                    Global Offices
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="leverage-genAI-caseStudies">
                <div className="container container--mid-v2">
                    <div className="leverage-genAI-caseStudies__text">
                        <div className="text-h1 text-fw-medium gap-4x">
                            Global enterprises fuel their growth engines with Sedin experts every
                            day
                        </div>
                    </div>
                    <ProjectGrid
                        CaseStudiesData={aicasestudies.allContentfulCasestudies.nodes}
                        ai
                    />
                </div>
            </section>
            {/* <section className="leverage-genAI-testimonials">
                <div className="container container--mid-v2">
                    <div></div>
                </div>
            </section> */}
            <section className="leverage-genAI-vision">
                <div className="container container--mid-v2">
                    <div className="leverage-genAI-vision__text">
                        <div className="text-h1 text-fw-medium gap-4x">
                            Reimagine Everything with AI
                        </div>
                    </div>
                    <div className="leverage-genAI-vision-content">
                        <div className="leverage-genAI-vision-content-item">
                            <div className="leverage-genAI-vision-content-item__name">
                                Expert Guidance
                            </div>
                            <div className="leverage-genAI-vision-content-item__description">
                                Benefit from the expertise of our experienced team of AI specialists
                                and industry professionals.
                            </div>
                        </div>
                        <div className="leverage-genAI-vision-content-item">
                            <div className="leverage-genAI-vision-content-item__name">
                                Tailored Approach
                            </div>
                            <div className="leverage-genAI-vision-content-item__description">
                                We understand that every business is unique. Our solutions are
                                customized to address your specific challenges and opportunities.
                            </div>
                        </div>
                        <div className="leverage-genAI-vision-content-item">
                            <div className="leverage-genAI-vision-content-item__name">
                                Practical Outcomes
                            </div>
                            <div className="leverage-genAI-vision-content-item__description">
                                Our goal is to deliver actionable insights and strategies that
                                translate into real-world results for your organization.
                            </div>
                        </div>
                        <div className="leverage-genAI-vision-content-item">
                            <div className="leverage-genAI-vision-content-item__name">
                                Suitable for all
                            </div>
                            <div className="leverage-genAI-vision-content-item__description">
                                Unlock top-tier models with unparalleled data management, AI
                                infrastructure, and cutting-edge business applications.
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="service-leaders-AI">
                <div className="container container--py">
                    <h2 className="service-leaders-AI__text text-h1 text-fw-medium text-clr-primary gap-4x">
                        Meet our Team
                    </h2>
                    <div className="service-leaders-AI__grid">
                        <div className="service-leaders-AI__item">
                            <div className="service-leaders-AI__item-avatar">
                                <StaticImage
                                    className="service-leaders-AI__item-avatar__image"
                                    src="../../../images/aichapter/Sudhakar.png"
                                    alt="leader-img"
                                    loading="lazy"
                                />
                            </div>
                            <div className="service-leaders-AI__item-content">
                                <div className="service-leaders-AI__item-content__name">
                                    Sudhakar
                                </div>
                                <div className="service-leaders-AI__item-content__designation">
                                    Solutions Architect
                                </div>
                            </div>
                        </div>
                        <div className="service-leaders-AI__item">
                            <div className="service-leaders-AI__item-avatar">
                                <StaticImage
                                    className="service-leaders-AI__item-avatar__image"
                                    src="../../../images/aichapter/Shrikant.jpg"
                                    alt="leader-img"
                                    loading="lazy"
                                />
                            </div>
                            <div className="service-leaders-AI__item-content">
                                <div className="service-leaders-AI__item-content__name">
                                    Shrikanth
                                </div>
                                <div className="service-leaders-AI__item-content__designation">
                                    Chief Data Scientist
                                </div>
                            </div>
                        </div>
                        <div className="service-leaders-AI__item">
                            <div className="service-leaders-AI__item-avatar">
                                <StaticImage
                                    className="service-leaders-AI__item-avatar__image"
                                    src="../../../images/aichapter/Thulasi_1.png"
                                    alt="leader-img"
                                    loading="lazy"
                                />
                            </div>
                            <div className="service-leaders-AI__item-content">
                                <div className="service-leaders-AI__item-content__name">
                                    Thulasi
                                </div>
                                <div className="service-leaders-AI__item-content__designation">
                                    Data Science Lead
                                </div>
                            </div>
                        </div>
                        <div className="service-leaders-AI__item">
                            <div className="service-leaders-AI__item-avatar">
                                <StaticImage
                                    className="service-leaders-AI__item-avatar__image"
                                    src="../../../images/aichapter/Harman_2.png"
                                    alt="leader-img"
                                    loading="lazy"
                                />
                            </div>
                            <div className="service-leaders-AI__item-content">
                                <div className="service-leaders-AI__item-content__name">Harman</div>
                                <div className="service-leaders-AI__item-content__designation">
                                    Solutions Architect
                                </div>
                            </div>
                        </div>
                        <div className="service-leaders-AI__item">
                            <div className="service-leaders-AI__item-avatar">
                                <StaticImage
                                    className="service-leaders-AI__item-avatar__image"
                                    src="../../../images/aichapter/Sparsh_1.png"
                                    alt="leader-img"
                                    loading="lazy"
                                />
                            </div>
                            <div className="service-leaders-AI__item-content">
                                <div className="service-leaders-AI__item-content__name">Sparsh</div>
                                <div className="service-leaders-AI__item-content__designation">
                                    Product Owner
                                </div>
                            </div>
                        </div>
                        <div className="service-leaders-AI__item">
                            <div className="service-leaders-AI__item-avatar">
                                <StaticImage
                                    className="service-leaders-AI__item-avatar__image"
                                    src="../../../images/aichapter/Afsar_1.png"
                                    alt="leader-img"
                                    loading="lazy"
                                />
                            </div>
                            <div className="service-leaders-AI__item-content">
                                <div className="service-leaders-AI__item-content__name">Afsar</div>
                                <div className="service-leaders-AI__item-content__designation">
                                    Data Scientist
                                </div>
                            </div>
                        </div>
                        <div className="service-leaders-AI__item">
                            <div className="service-leaders-AI__item-avatar">
                                <StaticImage
                                    className="service-leaders-AI__item-avatar__image"
                                    src="../../../images/aichapter/Apoorva_1.png"
                                    alt="leader-img"
                                    loading="lazy"
                                />
                            </div>
                            <div className="service-leaders-AI__item-content">
                                <div className="service-leaders-AI__item-content__name">
                                    Apoorva
                                </div>
                                <div className="service-leaders-AI__item-content__designation">
                                    Technical Researcher
                                </div>
                            </div>
                        </div>
                        {/* <div className="service-leaders-AI__item">
                            <div className="service-leaders-AI__item-avatar">
                                <StaticImage
                                    className="service-leaders-AI__item-avatar__image"
                                    src="../../../images/aichapter/Neelesh_1.png"
                                    alt="leader-img"
                                    loading="lazy"
                                />
                            </div>
                            <div className="service-leaders-AI__item-content">
                                <div className="service-leaders-AI__item-content__name">
                                    Neelesh
                                </div>
                                <div className="service-leaders-AI__item-content__designation">
                                    GenAI Developer
                                </div>
                            </div>
                        </div> */}
                        <div className="service-leaders-AI__item">
                            <div className="service-leaders-AI__item-avatar">
                                <StaticImage
                                    className="service-leaders-AI__item-avatar__image"
                                    src="../../../images/aichapter/Shamil_1.png"
                                    alt="leader-img"
                                    loading="lazy"
                                />
                            </div>
                            <div className="service-leaders-AI__item-content">
                                <div className="service-leaders-AI__item-content__name">Shamil</div>
                                <div className="service-leaders-AI__item-content__designation">
                                    GenAI Developer
                                </div>
                            </div>
                        </div>
                        <div className="service-leaders-AI__item">
                            <div className="service-leaders-AI__item-avatar">
                                <StaticImage
                                    className="service-leaders-AI__item-avatar__image"
                                    src="../../../images/aichapter/Akshay_1.png"
                                    alt="leader-img"
                                    loading="lazy"
                                />
                            </div>
                            <div className="service-leaders-AI__item-content">
                                <div className="service-leaders-AI__item-content__name">Akshay</div>
                                <div className="service-leaders-AI__item-content__designation">
                                    GenAI Developer
                                </div>
                            </div>
                        </div>
                        <div className="service-leaders-AI__item">
                            <div className="service-leaders-AI__item-avatar">
                                <StaticImage
                                    className="service-leaders-AI__item-avatar__image"
                                    src="../../../images/aichapter/Shivam_1.png"
                                    alt="leader-img"
                                    loading="lazy"
                                />
                            </div>
                            <div className="service-leaders-AI__item-content">
                                <div className="service-leaders-AI__item-content__name">Shivam</div>
                                <div className="service-leaders-AI__item-content__designation">
                                    GenAI Developer
                                </div>
                            </div>
                        </div>
                        <div className="service-leaders-AI__item">
                            <div className="service-leaders-AI__item-avatar">
                                <StaticImage
                                    className="service-leaders-AI__item-avatar__image"
                                    src="../../../images/aichapter/Archit_1.png"
                                    alt="leader-img"
                                    loading="lazy"
                                />
                            </div>
                            <div className="service-leaders-AI__item-content">
                                <div className="service-leaders-AI__item-content__name">Archit</div>
                                <div className="service-leaders-AI__item-content__designation">
                                    GenAI Developer
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="leverage-genAI-cta">
                <div className="container container--last">
                    <BlogCtaBanner
                        title="Ready to explore the limitless possibilities of Gen AI"
                        ctaText="Contact us"
                        ctaLink={"/contact/"}
                    />
                </div>
            </section>
        </Layout>
    );
};

export default LeverageGenAIPage;
